import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { AirArrivalsStyle } from '../style/AirArrivalsStyle';
var moment = require('moment');


const Pagination = ({totalItems, itemPerPage, setCurrentPage}) => {
  let pages = []

  for(let i = 1; i<= Math.ceil(totalItems/itemPerPage); i++){
    pages.push(i)
  }
  return(
      <div>
      {pages.map((page, index) => {
          return <button key={index} style={{padding: "10px", backgroundColor:"rgb(0, 66, 106)", color:"white", border:"1px solid white", marginRight:"2px"}} onClick={() => setCurrentPage(page)}>{page}</button>
      })}
      </div>
  )
}

const NewsArchive = (props) => {
 
  var hash= "";

  if (typeof window !== 'undefined') {
    hash=window.location.hash;
  }

  const [url] = useState("https://pojcdn.blob.core.windows.net/data/newsarchive.json")
  const [news, setNews] = useState([])
  const [focus, setFocus] = useState(hash==="")

  useEffect(() => {
    const fetchNews = async() => {
      try{
        const result = await axios(url)
        setNews(result.data.data.newsAll.nodes)
        setSearchNews(result.data.data.newsAll.nodes)
      } catch(error) {
        console.log("fetch error")
      }
    }
    fetchNews()
  },[])

  const [contentRows, setContentRows] = useState([hash])
  const [textInput, setTextInput] = useState("")
  const [searchNews, setSearchNews] = useState([])
  
  // const hash = ;
  // if (hash!==""){
  // //   setContentRows([hash]);
  //    setFocus(false)
  //  }


  const toggleRow = (id) => {
    if (typeof window !== 'undefined') {
      window.location.hash = id;
    };
      setContentRows([id]);
      setFocus(false)
  };
  const cleanup = () =>{
    setContentRows([]);
    if (typeof window !== 'undefined') {
      window.location.hash = "";
    };
    setFocus(true)
  }
    const onTextInput = (event) => {
    setTextInput (event.target.value)
    setCurrentPage(1)
    setSearchNews(news.filter(data => data.title.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0));
  }

  const [currentPage, setCurrentPage] = useState(1)
  const itemPerPage = 50;
  const lastitemIndex = currentPage * itemPerPage
  const firstItemIndex = lastitemIndex - itemPerPage
  const pageNews = searchNews.slice(firstItemIndex, lastitemIndex)


  return (
    <div css={AirArrivalsStyle}>
            <div className="arrivalscontainer">

      {focus === true ? (<>
        <h1>News Archive</h1>

      <input className="filter" type="search" onChange={onTextInput} value={textInput} placeholder="Search news archive" />

        <table id="arrivals">
        <thead>
          <th>Title</th>
          <th>Category</th>
          <th>Date</th>
        </thead>
        <tbody>
      {pageNews.map(data => (
        <>
          <tr key={data.id} onClick={() => toggleRow(data.id)}>
          <td style={{textDecoration:"underline", cursor:"pointer"}}>
              {data.title}
          </td>
          <td>{data.Newsfields.newsCategory}</td>
          {/* <td>{data.Newsfields.newsDate}</td> */}

          <td>{moment(data.Newsfields.newsDate, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY') }</td>

          
        </tr>

        </>
      ))}
      </tbody>
      </table>
      <br />
      <Pagination totalItems={searchNews.length} itemPerPage={itemPerPage} setCurrentPage={setCurrentPage}/>

      </>) : (<>
      {pageNews.map(data => (
        <>
          {(contentRows.includes('#'+data.id) || contentRows.includes(data.id)) && (
            <>
              <h1>{data.title}</h1>
              <p><strong>From {data.Newsfields.newsCategory}</strong></p>
              <p><strong>{moment(data.Newsfields.newsDate, 'YYYY-MM-DD HH:mm').format('Do MMMM YYYY') }</strong></p>

              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
              <button className='payOnlineLink' onClick={() => cleanup()}>Back to news archive</button>

            </>
          )}
        </>
      ))}</>) }
      </div>
    </div>
  );

  
}

export default NewsArchive;