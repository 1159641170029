import { css } from "@emotion/core"


export const AirArrivalsStyle = css`

.arrivalscontainer {
  max-width: 1140px;

#arrivals {
  font-family: Tahoma,Verdana,Arial,Sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#arrivals th {
  border: 1px solid #ddd;
  border-top-style: none;
  border-left-style: none;
  padding: 8px;
}

#arrivals th:last-child {
  border-right: 1px solid #00426a;
}
 
#arrivals td {
  border: 1px dashed rgb(157, 192, 233);
  padding: 8px;
}

#arrivals td small {
  font-size: 0.6rem;
}

#arrivals tr:nth-child(even){background-color: rgb(225, 242, 251);}

#arrivals tr:hover {background-color: #ddd;}

.headerBackground {
    padding: 12px;
    text-align: left;
    color: black;
    border: 1px solid rgb(0, 66, 106);
    background-color: rgb(225, 242, 251);

}

.headerBackground button
{
  background-color: #fff;
  border: 1px solid #00426a;  
  padding: 0.8rem 1rem;
  color: rgb(0, 66, 106);;
  margin-right:1rem;
  font-size: 1rem;
  margin-bottom:10px;
}

.headerBackground button.Active
{
  background-color: #00426a;
  border: 1px solid #46a2d9;  
  color:white;
}

.headerBackground button:hover
{
  background-color: #00426a;
  color:white;
}

#arrivals th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(0, 66, 106);
  color: white;
  @media (max-width: 600px) {
    font-size:11px;
  }
}

.buttonActive {
  background-color: rgb(0, 66, 106);
  border: 2px solid rgb(0, 66, 106);
  border-bottom-style: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right:10px;
  border-radius: 10px 10px 0 0;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }
}

.button {
  border: 2px solid rgb(0, 66, 106);
  border-bottom-style: none;
  background-color: #fff;
  color: rgb(0, 66, 106);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right:10px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  @media (max-width: 600px) {
    padding: 10px 20px;
  }
}

.buttonActive img {
  width: 25px;
  display: inline;
  filter: brightness(0) invert(1);
  margin-left:5px;
}

.button img {
  width: 25px;
  display: inline;
  margin-left:5px;
}


.filter {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom:20px;
}

.flightMobile {
  display:none;
  @media (max-width: 600px) {
    display:block;
    margin-top:5px;
  }
}

.flightDesktop {
  display: table-cell;
  @media (max-width: 600px) {
    display:none;
  }
}

}
`