import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideBar from "../components/sideBar"
import Image from "../components/image"
import MaterialTable from 'material-table';
import NewsArchive from "../components/NewsArchive"

import { layoutStyles } from '../style/layoutStyles';
import { MaterialTableStyle } from "../style/materialTableStyle"

export default ({ pageContext }) => {

    return (
    <Layout>
        <SEO title={"Latest News"} />
        <div css={layoutStyles} id="content">
        <div className="grid">
          <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead col-1-1">
                <Image scr={[]} Mode="port"/>  
                </div>
            </div>
          </div>
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-9-12">
                <NewsArchive />
              </div>

              <div id="aside" className="col-3-12">
                <SideBar FullWidth={true} />
              </div>

          </div>
        </div>
        <div className="whitespacer"></div>
      </div>

    </Layout>
  )
}